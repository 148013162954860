<template>
    <div class="row">
        <div class="col-12">
            <confirm-modal/>
        </div>
    </div>
</template>
<script>
import ConfirmModal from "@/components/elements/ConfirmModal";
    export default {
        components:{
            ConfirmModal
        },
        created() {
            this.init();
        },
        methods: {
            init() {
                this.confirmModal('Mesaj', ()=>{alert('ok')});
            }
        },
    };
</script>
